<template>
  <div id="item-view">
    <v-row>
      <v-col cols="12">
        <order-bio-panel
          v-if="itemData && itemData !== 'order not found'"
          :item-data="itemData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh-data="refreshData"
        ></order-bio-panel>
        <Error404
        v-else-if="itemData == 'order not found'"
        mode="dataError"
        :title="itemData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiAccountOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import Error404 from '@views/Error404'
import OrdersStoreModule from '../OrdersStoreModule'

// eslint-disable-next-line object-curly-newline
import OrderBioPanel from './message-bio-panel/OrderBioPanel.vue'

export default {
  components: {
    OrderBioPanel,
    Error404,
  },
  setup() {
    const ORDERS_APP_STORE_MODULE_NAME = 'app-orders'

    // Register module
    if (!store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDERS_APP_STORE_MODULE_NAME, OrdersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDERS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(null)
    const isPlanUpgradeDialogVisible = ref(false)

    const refreshData = () => {
      store
        .dispatch('app-orders/fetchOrder', { id: router.currentRoute.params.id })
        .then(response => {
          itemData.value = response
          itemData.value.details = itemData.value.details.filter(item => !item.is_add_on)
          itemData.value.details_all = []
          itemData.value.details.forEach(item => {
            itemData.value.details_all.push(item)
            if (item.add_ons?.length) {
              itemData.value.details_all = [...itemData.value.details_all, ...item.add_ons]
            }
            if (item.used_in_all?.length) {
              itemData.value.details_all = [...itemData.value.details_all, ...item.used_in_all]
            }
          })
        })
        .catch(error => {
          if (error.response?.status === 404) {
            itemData.value = {}
          }
        })
    }
    refreshData()
    const tabs = [{ icon: mdiAccountOutline, title: 'Employees' }]

    return {
      refreshData,
      tabs,
      isPlanUpgradeDialogVisible,
      itemData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
